<template>
  <div style="margin-top: 100px;">
    <div class="d-flex justify-between items-end mx-3 my-3">
      <v-btn fab x-small class="btn btn-success" dark @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <button class="btn btn-success " @click="dialog = true">
        <span class="text-sm font-semibold">
          agregar cuenta
        </span>
      </button>
    </div>

    <LayoutPage :title="'Promocion 3 cuotas sin interés: Cuentas asociadas'">
      <v-data-table
        :headers="headers"
        :items="[]"
        :items-per-page="rowsPerPage"
        :loading="loading"
        loading-text="Cargando... Espere por favor"
        no-results-text="No se encontraron registros"
        no-data-text="No se encontraron registros"
        calculate-widths
        hide-overlay
        hide-default-footer
        disable-sort
      >
        <template v-slot:[`item.cuota_sin_interes`]="{ item }">
          <v-chip :color="item.cuota_sin_interes ? 'green' : 'red'" dark>
            {{ item.cuota_sin_interes ? "Si" : "No" }}
          </v-chip>
        </template>

        <template v-slot:[`item.porcentaje`]="{ item }">
          {{ item.porcentaje > 0 ? `${item.porcentaje} %` : "-" }}
        </template>

        <template v-slot:[`item.acciones`]="{ item }">
          <v-btn fab x-small @click="verDetalle(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>

          <v-btn
            fab
            x-small
            @click="verCuentas(item)"
            class="ml-3"
            color="info"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>

          <v-btn
            fab
            x-small
            dark
            @click="verFormEdit(item)"
            class="ml-3"
            color="orange"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <!-- cantidad de registros por pagina -> actualizar numero de registros por pagina -->
        <template v-slot:footer>
          <v-row class="no-gutters mt-2 mx-2" v-if="false">
            <v-flex xs12>
              <span class="mt-2">Mostrar</span>
              <v-menu top>
                <template v-slot:activator="{ on }">
                  <v-btn dark text color="primary" class="ml-2" v-on="on">
                    {{ rowsPerPage }}
                    <v-icon>arrow_drop_down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in rowsPerPageArray"
                    :key="index"
                    @click="updateRowsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
          </v-row>
        </template>
      </v-data-table>
    </LayoutPage>

    <AgregarCuenta v-if="dialog" :dialog="dialog" @close="dialog = false" />
  </div>
</template>

<script>
import LayoutPage from "../../LayoutPage.vue";
import AgregarCuenta from "./AgregarCuenta.vue";

export default {
  name: "Cuentas",
  components: { LayoutPage, AgregarCuenta },
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Promocion", value: "nombre" },
        { text: "Porcentaje", value: "porcentaje" },
        { text: "Sin interés", value: "cuota_sin_interes" },
        { text: "Acciones", value: "acciones" },
      ],
      loading: false,
      rowsPerPage: 10,
      rowsPerPageArray: [10, 30, 50],
    };
  },
};
</script>
