<template>
  <v-dialog v-model="internalDialog" width="600">
    <v-card>
      <v-card-title>
        Agregar Cuenta
      </v-card-title>

      <div class="mx-7 mb-6">
        <v-form ref="form">
          <v-text-field
            label="Porcentaje de descuento"
            type="number"
          ></v-text-field>

          <div>
            <p class="mb-2 text-start opacity-80">
              <v-icon style="font-size: 20px">mdi-calculator</v-icon>
              Cuotas permitidas
            </p>

            <Select2
              :options="[]"
              :settings="{
                language: { noResults: () => 'No se encontraron resultados' },
                placeholder: 'Favor de seleccionar cuotas',
                multiple: true,
                width: '100%',
              }"
              style="height: 30px !important;"
            />
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AgregarCuenta",
  props: ["dialog"],
  data() {
    return {
      internalDialog: this.dialog,
    };
  },
};
</script>
