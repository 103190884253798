var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"100px"}},[_c('div',{staticClass:"d-flex justify-between items-end mx-3 my-3"},[_c('v-btn',{staticClass:"btn btn-success",attrs:{"fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('button',{staticClass:"btn btn-success ",on:{"click":function($event){_vm.dialog = true}}},[_c('span',{staticClass:"text-sm font-semibold"},[_vm._v(" agregar cuenta ")])])],1),_c('LayoutPage',{attrs:{"title":'Promocion 3 cuotas sin interés: Cuentas asociadas'}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":[],"items-per-page":_vm.rowsPerPage,"loading":_vm.loading,"loading-text":"Cargando... Espere por favor","no-results-text":"No se encontraron registros","no-data-text":"No se encontraron registros","calculate-widths":"","hide-overlay":"","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.cuota_sin_interes",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.cuota_sin_interes ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.cuota_sin_interes ? "Si" : "No")+" ")])]}},{key:"item.porcentaje",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.porcentaje > 0 ? ((item.porcentaje) + " %") : "-")+" ")]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.verDetalle(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"ml-3",attrs:{"fab":"","x-small":"","color":"info"},on:{"click":function($event){return _vm.verCuentas(item)}}},[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-btn',{staticClass:"ml-3",attrs:{"fab":"","x-small":"","dark":"","color":"orange"},on:{"click":function($event){return _vm.verFormEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"footer",fn:function(){return [(false)?_c('v-row',{staticClass:"no-gutters mt-2 mx-2"},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"mt-2"},[_vm._v("Mostrar")]),_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.rowsPerPage)+" "),_c('v-icon',[_vm._v("arrow_drop_down")])],1)]}}],null,false,2705691625)},[_c('v-list',_vm._l((_vm.rowsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateRowsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1)],1)],1):_vm._e()]},proxy:true}],null,true)})],1),(_vm.dialog)?_c('AgregarCuenta',{attrs:{"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }